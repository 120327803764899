<template>
    <v-container class="pa-0" fluid>
        <page-title
                :heading="$t('erp.lang_Zusatzstoffe')"
                :icon="icon"
                :subheading="$t('erp.lang_AddAdditive')"
                show-previous-button
        ></page-title>
        <div class="app-main__inner">
            <additives-create-component/>
        </div>
    </v-container>
</template>

<script>
    import AdditivesCreateComponent from "../../../../components/erp/settings/additive/AdditivesCreateComponent";
    import PageTitle from "../../../../Layout/Components/PageTitle";
    export default {
        name: "AdditivesCreate",
        components: {PageTitle, AdditivesCreateComponent},
        data(){
            return {
                icon: "pe-7s-tools icon-gradient bg-tempting-azure"
            }
        }
    }
</script>

<style scoped>

</style>