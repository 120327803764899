<template>
    <v-container fluid>
        <v-card elevation="0" outlined>
            <v-card-title class="headline">
                {{$t('erp.lang_AddAdditive')}}
            </v-card-title>
            <v-divider class="pa-0 ma-0"/>
            <v-card-text>
                <v-row align="center" align-content="center" justify="center" no-gutters >
                    <v-col cols="12" md="6" sm="10">
                            <v-text-field outlined
                                          @focus="showTouchKeyboard"
                                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                          v-model="additiveAdd"
                                          :label="$t('erp.lang_Zusatzstoffe')"
                                          autocomplete="off"
                                          required
                            ></v-text-field>
                            <v-btn color="primary"
                                   :loading="loading"
                                   :disabled="additiveAdd.length < 1"
                                   @click="addData"
                                   class="ma-0"
                                    block large
                            >
                                {{$t('generic.lang_add') }}
                            </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
            <vue-touch-keyboard id="onScreenKeyboard" class="internalWidth" :options="touchKeyboard.options"
                                :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                                :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                                :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
        </div>
    </v-container>
</template>

<script>
    import {ENDPOINTS} from "../../../../config";
    import {Events} from "../../../../plugins/events";
    import mixin from "../../../../mixins/KeyboardMixIns";

    export default {
        name: "AdditivesCreateComponent",
        mixins: [mixin],
        data(){
            return{
                additiveAdd:'',
                loading:false,
                ENDPOINTS,
            }
        },
        methods:{
            addData: function() {
                let self = this;
                self.loading=true;
                this.axios.post(ENDPOINTS.ERP.ADDITIVE.CREATE, {
                    addAdditiveName: this.additiveAdd
                }).then((res) => {
                    self.loading=false;
                    if (res.data.status === 'SUCCESS') {
                        Events.$emit("showSnackbar", {
                            message: this.$t('erp.lang_additive_added'),
                            color: "success"
                        });
                        self.additiveAdd = "";


                    } else {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_errorOccurred'),
                            color: "error"
                        });
                    }
                }).catch(() => {
                    self.loading=false;
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_errorOccurred'),
                        color: "error"
                    });
                });
            },
        }


    }
</script>

<style scoped>

</style>